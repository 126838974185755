function Purchase(props) {
  //console.log(props)
  let items = [];
  // TODO:  add product cats
  props.cart.products.forEach((item, i) => {
    //console.log(item)
    let product = {
      item_id: item.productId,
      item_name: item.name,
      price: parseFloat(Number(item.totalPrice.replace("zł", "")).toFixed(2)),
      quantity: item.qty,
      item_brand: "Shroom",
      item_category: item.productCategories
        ? item.productCategories.nodes[0].name
        : "",
    };
    items.push(product);
  });

  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: "T_" + props.mod.transactionId, // Replace with your desired format
      value: parseFloat(
        Number(props.cart.totalProductsPrice.replace("zł", "")).toFixed(2)
      ),
      currency: props.mod.currency === "PLN" ? "PLN" : "PLN",
      tax: parseFloat(Number(props.cart.totalTax.replace("zł", "")).toFixed(2)),
      shipping: parseFloat(
        Number(props.cart.shippingTotal.replace("zł", "")).toFixed(2)
      ),
      coupon: parseFloat(Number(props.cart.discountTotal).toFixed(2)), // Convert coupons to a comma-separated string
      items: items,
    },
  });
}

export default Purchase;
