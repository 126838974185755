import { gql } from "@apollo/client";

const CHECKOUT_MUTATION = gql`
mutation CHECKOUT_MUTATION($input: CheckoutInput!) {
  checkout(input: $input) {
    clientMutationId
    order {
      id
      orderKey
      refunds {
        nodes {
          amount
        }
      }
      status
      date
      currency
      total
      subtotal
      shippingTotal
      paymentMethod
      discountTotal
      couponLines {
        nodes {
          code
          discount
        }
      }
    }
    result
    redirect
  }
}
`;

export default CHECKOUT_MUTATION;
